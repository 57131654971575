<template>
  <!-- 進行中訂單列表 -->
  <div
    class="bg-white border border-primary border-2 p-4 position-relative"
    :class="$style[`min-height-100`]"
  >
    <div
      v-for="(row,index) in ordersList"
      :key="index"
      class="mb-3"
    >
      <div
        class="bg-primary text-white d-flex justify-content-between p-2 py-md-3 px-md-4"
        role="button"
        @click="setShowOrders(row.orderID)"
      >
        <span class="d-none d-lg-inline">{{ row.insertTime ?? '-' }}</span>
        <span class="d-inline d-lg-none">{{ row.insertTime ? row.insertTime.split(' ')[0] : '-' }}</span>
        <span>金額：$ {{ row.payAmt ?? 0 }}</span>
        <span>狀態：{{ row.status ?? '-' }}</span>
        <span>配送：{{ row.shipStatus ?? '-' }}</span>
      </div>
      <div
        class="w-100 bg-white border"
        :class="[[$style['order']], {[$style['active']]: showOrders?.[row.orderID]}]"
      >
        <div
          class="container-lg my-2"
        >
          <div class="text-start mb-4">
            訂單編號：{{ row.orderID }}
          </div>
          <div class="row align-items-center my-lg-2 d-none d-lg-flex">
            <div class="col-4">
              商品說明
            </div>
            <div class="text-center col-lg-2">
              定價
            </div>
            <div class="text-center col-lg-2">
              數量
            </div>
            <div class="text-end col-lg-2">
              購物金折抵
            </div>
            <div class="text-end col-lg-2">
              折價券折抵
            </div>
          </div>
          <div
            v-for="(detail,detailIndex) in row.orderDetails"
            :key="detailIndex"
            class="row align-items-center"
          >
            <div class="col-4">
              {{ detail.name ?? '-' }}
            </div>
            <div class="text-center col-2 col-lg-2 mb-3 d-none d-lg-block">
              {{ detail.fixedPrice ?? 0 }}
            </div>
            <div class="text-center col-2 col-lg-2 mb-3 d-none d-lg-block">
              {{ detail.qty ?? 0 }}
            </div>
            <div class="text-end col-2 col-lg-2 mb-3 text-danger">
              - {{ detail.bonusAssign ?? 0 }}
            </div>
            <div class="text-end col-2 col-lg-2 mb-3 text-danger">
              - {{ detail.redeemAmt ?? 0 }}
            </div>
          </div>
          <hr>
          <div class="row mb-3">
            <span class="col-8 ">小計</span>
            <span class="col-4 text-end">{{ row.amount ?? 0 }}</span>
          </div>
          <div class="row mb-3">
            <span class="col-8">運費</span>
            <span class="col-4 text-end">
              {{ row.shipFee ?? 0 }}</span>
          </div>
          <div
            v-if="row.couponUse"
            class="row mb-3"
          >
            <span class="col-8">使用折扣碼</span>
            <span class="col-4 text-end text-danger">-
              {{ row.couponUse ?? 0 }}</span>
          </div>
          <div
            v-if="row.bonusUse"
            class="row mb-3"
          >
            <span class="col-8">使用購物金折抵</span>
            <span class="col-4 text-end text-danger">-
              {{ row.bonusUse ?? 0 }}</span>
          </div>
          <!--                                         <div v-if="order.discount" -->
          <!--                                              class="row mb-3"> -->
          <!--                                             <span class="col-8">活動折抵 -->
          <!--                                                 {{order.discount.name}}</span> -->
          <!--                                             <span class="col-4 text-end text-danger">- -->
          <!--                                                 {{order.discount.price}}</span> -->
          <!--                                         </div> -->
          <hr>
          <div class="row mb-3">
            <span class="col-8">支付金額</span>
            <span class="col-4 text-end">$ {{ row.payAmt }}</span>
          </div>
        </div>
        <!-- <div
          role="button"
          class="d-flex align-items-center justify-content-center"
          @click="setShowOrders(row.orderID)"
        >
          <Icon
            name="next"
            :class="[[$style['down']], {[$style['active']]: showOrders?.[row.orderID]}]"
          />
        </div> -->
      </div>
    </div>
    <!--empty msg-->
    <div
      v-if="ordersList.length===0"
      class="position-absolute top-50 start-50 translate-middle d-flex flex-column"
    >
      <span class="d-block mb-2"> 目前還沒有訂單唷</span>
      <router-link
        to="/product/list"
        class="btn btn-primary text-white rounded-0"
      >
        馬上去逛逛
      </router-link>
    </div>

    <!-- page nav -->
    <PagePanigation
      v-if="!isLoading && ordersList.length!==0"
      class="d-none d-md-flex"
      :current-page="page?.current"
      :total-page="page?.totalPage"
      @setPage="setPage"
    />
  </div>
</template>
<script>
import { useStore } from 'vuex'
import { computed, inject, toRef, ref, reactive, watch } from 'vue'
import PagePanigation from '@/components/PagePanigation'
export default {
  name: 'MemberOrders',
  components: {
    PagePanigation
  },
  props: {
    showComplete: {
      type: Boolean,
      default: false
    }
  },
  async setup (props) {
    const { getters, dispatch } = useStore()

    const showComplete = toRef(props, 'showComplete')
    const showOrders = ref({})
    const setShowOrders = (orderID) => {
      showOrders.value[orderID] = !showOrders.value[orderID]
    }

    const page = reactive({
      current: 1,
      totalPage: 1,
      totalElement: 0
    })
    const pageSize = ref(20)

    const isLoading = computed(() => getters.getIsLoading)
    const memberID = computed(() => getters['member/getMemberID'])
    const ordersCompleted = computed(() => getters['member/getOrdersCompleted'])
    const ordersInProcess = computed(() => getters['member/getOrdersInProcess'])

    const setAlert = inject('setAlert')

    const ordersList = computed(() => {
      return showComplete.value ? ordersCompleted.value : ordersInProcess.value
    })

    const setPage = (page) => {
      showComplete.value ? readOrdersCompleted(page) : readOrdersInProcess(page)
    }

    const readOrdersInProcess = async (newPage) => {
      const payload = {
        memberID: memberID.value,
        page: newPage || page.current,
        offset: page.current * pageSize.value
      }

      try {
        await dispatch('member/readOrdersInProcess', { payload })
      } catch (error) {
        // console.log('get user info error: ' + ', ' + error)
        setAlert(true, false, error)
      }
    }

    const readOrdersCompleted = async (newPage) => {
      const payload = {
        memberID: memberID.value,
        page: newPage || page.current,
        offset: page.current * pageSize.value
      }

      try {
        const response = await dispatch('member/readOrdersCompleted', { payload })
        const data = response.data
        page.offset = data.data?.page?.offset || 0
        page.totalElement = data.data?.page?.totalElement || 0
        page.totalPage = data.data?.page?.totalPage || 1
      } catch (error) {
        // console.log('get user info error: ' + ', ' + error)
        page.totalElement = 0
        setAlert(true, false, error)
      }
    }

    watch(showComplete, async () => {
      showOrders.value = {}
      if (showComplete.value) {
        await readOrdersCompleted()
      } else {
        await readOrdersInProcess()
      }
    }, { immediate: true })

    return { ordersList, isLoading, setPage, page, showOrders, setShowOrders }
  }
}
</script>
<style lang="scss" module>
.order{
  max-height: 0;
  overflow: hidden;
  transition: max-height .3s;
}

.order.active{
  max-height: 1000px;
}

.down{
  width: 0.6rem;
  height: 0.6rem;
  transform: rotate(90deg);
  transition: transform .3s;
}

.down.active{
  transform: rotate(270deg);
}

.min-height-100{
  min-height: 100%;
}
</style>
